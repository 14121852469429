import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { useParams } from 'react-router';

import Loader from 'components/loader';
import BeliefsChart from 'features/beliefs-chart';


import {
	Card,
	CardContent,
	CardHeader,
	FormControl,
	Grid,
	Select,
	InputLabel,
	MenuItem,
	SelectChangeEvent,
	Snackbar,
	IconButton,
	Tooltip,
	Dialog,
	DialogActions,
	Button,
	DialogContent,
	DialogContentText,
	DialogTitle,
	TextField,
	Table,
	TableRow,
	TableCell,
	TableHead,
	TableBody,
} from '@mui/material';

import {
	Close as CloseIcon,
	FilterAltOff as FilterOffIcon,
	FileDownload as FileDownloadIcon
} from '@mui/icons-material';

import {
	updateFilter,
	selectDashboard,
	closePhysicianSnackbar,
	setInsightSnackbarState,
	loadDashboardYears,
	loadDashboardWithData,
	clearFilters,
	getDiseaseAreaSupportTables,
	closeExportModal,
	openExportModal,
	updateStartDate,
	updateStopDate,
	closePreviewModal,
	openPreviewModal,
	updatePreview

} from '../../store/slices/dashboard'


import InsightsDialog from '../../features/insights-dialog';
import { selectInsight, setDialogState } from '../../store/slices/insight';
import { selectBusinessUnit, setBusinessUnit } from '../../store/slices/business-unit';
import { selectAdmin } from '../../store/slices/admin';
import { SupportTableProps } from '../../utils/types';
import PieChart from '../../features/pie-chart';
import SelectDiseaseAreaWarning from './select-disease-area';
import { useEffect, useState, SyntheticEvent } from 'react';
import InsightsClient from "../../api/insights-client";
import NoBusinessUnitsWarning from "./no-business-units-warning";

import { DateTime } from "luxon";

export interface FilterProps {
	diseaseArea: SupportTableProps;
	year: SupportTableProps;
	region: SupportTableProps;
	influence: SupportTableProps;
	population: SupportTableProps;
	physician: SupportTableProps;
	quarter: SupportTableProps;
	lastSelected: string;
	startDate: DateTime | null;
	stopDate: DateTime | null;
}

const Dashboard = () => {

	const [loading, setLoading] = useState(true);
	const [loadingDiseaseArea, setLoadingDiseaseArea] = useState(false);
	const { guid } = useParams<any>();


	const dispatch = useAppDispatch();
	const adminStore = useAppSelector(selectAdmin);
	const dashboard = useAppSelector(selectDashboard);
	const businessUnits = useAppSelector(selectBusinessUnit);
	const insight = useAppSelector(selectInsight);

	useEffect(() => {
		if (!guid) {
			return
		}

		dispatch(setBusinessUnit(guid));
	}, [guid, dispatch]);

	useEffect(() => {
		if(!businessUnits.businessUnits || !adminStore.accessToken) {
			return;
		}

		if(!businessUnits.selectedBusinessUnitId) {
			setLoading(false)
			return;
		}

		setLoading(true);

		dispatch(loadDashboardYears(businessUnits.selectedBusinessUnitId))
			.then(() => {
				setLoading(false);
			});
	}, [businessUnits.selectedBusinessUnitId, adminStore.accessToken, businessUnits.businessUnits, dispatch]);

	useEffect(
		() => {
			if (!businessUnits.selectedBusinessUnitId || !dashboard.filters.year || dashboard.filters.year.id === '') {
				return;
			}
				
			dispatch(getDiseaseAreaSupportTables({
				businessUnitId: businessUnits.selectedBusinessUnitId,
				year: Number(dashboard.filters.year.id)
			}));

		},
		[dashboard.filters.year, businessUnits.selectedBusinessUnitId, dispatch]
	);

	useEffect(() => {
		if (dashboard.filters.diseaseArea.id.length > 0) {
			setLoadingDiseaseArea(true);
			dispatch(loadDashboardWithData(dashboard.filters)).then(() => {
				setLoadingDiseaseArea(false);
			});
		}
	}, [dashboard.filters, dispatch]);

	const handleExportGraphData = async () => {

		let filename = dashboard.diseaseArea ? dashboard.diseaseArea.name.replace(/[^a-zA-Z0-9 -]/g, '').replaceAll(" ","-") : 'error'
		let queryFilters: any = {
			id: dashboard.filters.diseaseArea.id,
			year: dashboard.filters.year.id,
		}

		if(dashboard.filters.startDate === undefined){
			queryFilters.startDate = ""
		}
		if(dashboard.filters.stopDate === undefined){
			queryFilters.stopDate = ""
		}
		
		if(dashboard.filters.startDate !== null) queryFilters.startDate = dashboard.filters.startDate
		if(dashboard.filters.stopDate !== null) queryFilters.stopDate = dashboard.filters.stopDate
		if(dashboard.filters.influence && dashboard.filters.influence.id) queryFilters.influenceId = dashboard.filters.influence.id
		if(dashboard.filters.physician && dashboard.filters.physician.id) queryFilters.physicianId = dashboard.filters.physician.id
		if(dashboard.filters.population && dashboard.filters.population.id) queryFilters.populationId = dashboard.filters.population.id
		if(dashboard.filters.region && dashboard.filters.region.id) queryFilters.regionId = dashboard.filters.region.id
		if(dashboard.filters.quarter && dashboard.filters.quarter.id) queryFilters.quarterId = dashboard.filters.quarter.id

		await (new InsightsClient()).exportDiseaseArea(adminStore.accessToken, queryFilters, filename)
	}

	const handleInsightsSubmit = () => {
		dispatch(setDialogState(false));
		dispatch(setInsightSnackbarState(true));
		dispatch(loadDashboardWithData(dashboard.filters));
	}

	const handleInsightsClose = () => {
		dispatch(setDialogState(false));
	}

	const handlePhysicianCreated = () => {
		dispatch(loadDashboardWithData(dashboard.filters));
	}

	const handleFilterChange = (event: SelectChangeEvent) => {
		const { name, value } = event.target;
		if (name.toLowerCase() === 'diseasearea') {
			dispatch(clearFilters());
		}
		dispatch(updateFilter({ id: value, value: name }));
	}

	const handleClosePhysicianSnackbar = (event: SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}

		dispatch(closePhysicianSnackbar())
	}

	const handleCloseInsightSnackbar = (event: SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		dispatch(setInsightSnackbarState(false))
	}

	const handleCloseExportModal = () => {
		dispatch(closeExportModal())
	}

	const handleOpenExportModal = () => {
		dispatch(openExportModal())
	}

	const handleClosePreviewModal = () => {
		dispatch(closePreviewModal())
	}

	const handleOpenPreviewModal = async () => {
		let queryFilters: any = {
			id: dashboard.filters.diseaseArea.id,
			year: dashboard.filters.year.id,
			startDate: dashboard.filters.startDate?.toISODate(),
			stopDate: dashboard.filters.stopDate?.toISODate()
		}

		if(dashboard.filters.influence && dashboard.filters.influence.id) queryFilters.influenceId = dashboard.filters.influence.id
		if(dashboard.filters.physician && dashboard.filters.physician.id) queryFilters.physicianId = dashboard.filters.physician.id
		if(dashboard.filters.population && dashboard.filters.population.id) queryFilters.populationId = dashboard.filters.population.id
		if(dashboard.filters.region && dashboard.filters.region.id) queryFilters.regionId = dashboard.filters.region.id
		if(dashboard.filters.quarter && dashboard.filters.quarter.id) queryFilters.quarterId = dashboard.filters.quarter.id
		
		await (new InsightsClient()).getPreview(adminStore.accessToken, queryFilters).then((res => {
			dispatch(updatePreview(res))
			dispatch(openPreviewModal())
		}))
		
		
	}

	const handleStartDateChange = (event: any) => {
		dispatch(updateStartDate(event.target.value));
	}

	const handleStopDateChange = (event: any) => {
		dispatch(updateStopDate(event.target.value));
	}

	const physicianSnackbarAction = (
		<>
			<IconButton
				size="small"
				aria-label="close"
				color="inherit"
				onClick={handleClosePhysicianSnackbar}
			>
				<CloseIcon fontSize="small" />
			</IconButton>
		</>
	);

	const insightSnackbarAction = (
		<>
			<IconButton
				size="small"
				aria-label="close"
				color="inherit"
				onClick={handleCloseInsightSnackbar}
			>
				<CloseIcon fontSize="small" />
			</IconButton>
		</>
	);

	return (
		<>
			{
				loading
					? <Loader />
					: !businessUnits.selectedBusinessUnitId ? <NoBusinessUnitsWarning />
					:
					<>
						<Card sx={{ mb: 2 }}>
							<CardHeader
								action={
									<>
										<Tooltip title="Export Disease Area Data" arrow={true} dir="up">
											<span>
												<IconButton aria-label="Export Disease Area Data" disabled={dashboard.diseaseArea === null} onClick={()=> handleOpenExportModal()}>
													<FileDownloadIcon />
												</IconButton>
											</span>
										</Tooltip>
										<Tooltip title="Clear filters" arrow={true} dir="up">
											<IconButton aria-label="clear filters" onClick={() => dispatch(clearFilters()) }>
												<FilterOffIcon />
											</IconButton>
										</Tooltip>
									</>
								}
								title="Filters"
								subheader="Filter the dashboard by selecing one or more of the filters."
							/>
							<CardContent>
								<Grid container spacing={2} flexWrap={{ lg: 'nowrap' }}>
									<Grid item lg={2} md={4} sm={6} xs={12}>
										<FormControl fullWidth size="small" required key='year-select-key'>
											<InputLabel id="year-select-label">Fiscal Year</InputLabel>
											<Select

												labelId="year-select-label"
												id="year-select"
												name="year"
												value={dashboard.filters.year.id}
												label="Fiscal Year"
												required
												onChange={handleFilterChange}
											>
												{
													dashboard.supportTables.years && dashboard.supportTables.years.map((year, index) => (
														<MenuItem value={year.id} key={index}>{year.value}</MenuItem>
													))
												}
											</Select>
										</FormControl>
									</Grid>

									<Grid item lg={2} md={4} sm={6} xs={12}>
										<FormControl fullWidth size="small" required key='disease-area-key'>
											<InputLabel id="disease-area-select-label">Disease Area</InputLabel>
											<Select
												labelId="disease-area-select-label"
												id="disease-area-select"
												name="diseaseArea"
												value={dashboard.filters.diseaseArea.id}
												label="Disease Area"
												required
												onChange={handleFilterChange}
												disabled={dashboard.filters.year.id === '' }
											>
												{dashboard.supportTables.diseaseAreas && dashboard.supportTables.diseaseAreas.map((diseaseArea, index) => (
													<MenuItem value={diseaseArea.id} key={index}>{diseaseArea.value}</MenuItem>
												))}

											</Select>
										</FormControl>
									</Grid>


									<Grid item lg={2} md={4} sm={6} xs={12}>
										<FormControl fullWidth size="small" key='region-select-key'>
											<InputLabel id="region-select-label">Region</InputLabel>
											<Select
												labelId="region-select-label"
												id="region-select"
												name="region"
												value={dashboard.filters.region.id ?? ''}
												label="Region"
												onChange={handleFilterChange}
												disabled={dashboard.diseaseArea === null}
											>
												<MenuItem value={0}>None</MenuItem>
												{
													dashboard.supportTables && dashboard.supportTables.regions.map((region, index) => (
														<MenuItem value={region.id} key={index}>{region.value}</MenuItem>
													))
												}
											</Select>
										</FormControl>
									</Grid>
									<Grid item lg={2} md={4} sm={6} xs={12}>
										<FormControl fullWidth size="small" key='influence-select-key'>
											<InputLabel id="influence-select-label">Influence</InputLabel>
											<Select
												labelId="influence-select-label"
												id="influence-select"
												name="influence"
												value={dashboard.filters.influence.id ?? ''}
												label="Influence"
												onChange={handleFilterChange}
												disabled={dashboard.diseaseArea === null}
											>
												<MenuItem value={0}>None</MenuItem>
												{dashboard.supportTables && dashboard.supportTables.influences.map((influence, index) => (
													<MenuItem value={influence.id} key={index}>{influence.value}</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>

									<Grid item lg={2} md={4} sm={6} xs={12}>
										<FormControl fullWidth size="small" key='population-select-key'>
											<InputLabel id="population-select-label">Population</InputLabel>
											<Select
												labelId="population-select-label"
												id="population-select"
												name="population"
												value={dashboard.filters.population.id ?? ''}
												label="Population"
												onChange={handleFilterChange}
												disabled={dashboard.diseaseArea === null}
											>
												<MenuItem value={0}>None</MenuItem>
												{dashboard.supportTables && dashboard.supportTables.populations.map((population, index) => (
													<MenuItem value={population.id} key={index}>{population.value}</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid item lg={2} md={4} sm={6} xs={12}>
										<FormControl fullWidth size="small" key='kol-select-key'>
											<InputLabel id="kol-select-label">KOL Name</InputLabel>
											<Select
												labelId="kol-select-label"
												id="kol-select"
												name="physician"
												value={dashboard.filters.physician.id ?? ''}
												label="KOL Name"
												onChange={handleFilterChange}
												disabled={dashboard.diseaseArea === null}
											>
												<MenuItem value={0}>None</MenuItem>
												{
													dashboard.supportTables && dashboard.supportTables.physicians.map((physician, index) => (
														<MenuItem value={physician.id} key={index}>{physician.value}</MenuItem>
													))
												}
											</Select>
										</FormControl>
									</Grid>
									<Grid item lg={2} md={4} sm={6} xs={12}>
										<FormControl fullWidth size="small" key='quarter-select-key'>
											<InputLabel id="quarter-select-label">Quarter</InputLabel>
											<Select
												labelId="quarter-select-label"
												id="quarter-select"
												name="quarter"
												value={dashboard.filters.quarter.id ?? ''}
												label="Quarter"
												onChange={handleFilterChange}
												disabled={dashboard.diseaseArea === null}
											>
												{dashboard.supportTables && dashboard.supportTables.quarters.map((quarter, index) => (
													<MenuItem value={quarter.id} key={index}>{quarter.value}</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</CardContent>
						</Card>

						{
							dashboard.diseaseArea === null
								? <SelectDiseaseAreaWarning />
								: <>
									{
										loadingDiseaseArea
											? <Loader />
											:
											<>
												<Grid container sx={{ mb: 2, maxWidth: '100%' }}>
													<Grid item xs={12}>
														<Card>
															<CardHeader title="Overall MCP Rating" subheader="The MCP ratings are determined by the selected filters."></CardHeader>
															<CardContent>
																<BeliefsChart 
																	totalNumOfInsights={dashboard.totalNumOfInsights!} 
																	overallBeliefScore={dashboard.overallBeliefScore} 
																	diseaseArea={dashboard.diseaseArea} 
																/>
															</CardContent>
														</Card>
													</Grid>
												</Grid>


												<Grid container spacing={2} sx={{ mb: 2 }}>
													{
														dashboard.diseaseArea?.questions.map((question, index) => (
															<Grid item lg={3} md={4} sm={6} xs={12} key={`question-${index}`}>
																<PieChart question={question} />
															</Grid>
														))
													}
												</Grid>
											</>
									}
								</>
						}

						{
							dashboard.diseaseArea && dashboard.supportTables && loadingDiseaseArea === false &&
							<InsightsDialog
								diseaseArea={dashboard.diseaseArea}
								years={dashboard.supportTables.years}
								quarters={dashboard.supportTables.quarters}
								open={insight.dialogState}
								onClose={handleInsightsClose}
								onPhysicianCreated={handlePhysicianCreated}
								onSubmit={handleInsightsSubmit}
								currentQuarter={dashboard.currentQuarter}
							/>
						}

						{
							
							(dashboard.notes !== null && (dashboard.notes.generalNotes.some(String) || dashboard.notes.followUpNotes.some(String))) && 
							<Card sx={{ mb: 2 }}>
								<CardHeader
									title = "Notes"
									subheader = "These are filtered by the KOL"
								/>
								<CardContent>
									<Grid>
										<Card>
											<CardHeader title="General Notes"></CardHeader>
												{dashboard.notes.generalNotes.map(note => (
													<CardContent>{note}</CardContent>
												
												))}
										</Card>
									</Grid>
									<Grid style={{paddingTop:'10px'}}>
										<Card>
											<CardHeader title="Follow-up Action Items"></CardHeader>
												
												{dashboard.notes.followUpNotes.map(note => (
													<CardContent>{note}</CardContent>
												))}
										</Card>
									</Grid>
								</CardContent>
							</Card>
						}
					</>
			}

			<Snackbar
				open={dashboard.physicianSnackbar}
				autoHideDuration={6000}
				onClose={handleClosePhysicianSnackbar}
				message="Physician Added"
				action={physicianSnackbarAction}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			/>

			<Snackbar
				open={dashboard.insightSnackbar}
				autoHideDuration={6000}
				onClose={handleCloseInsightSnackbar}
				message="Insight Added"
				action={insightSnackbarAction}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			/>

			<Dialog maxWidth="sm" fullWidth open={dashboard.exportModal} onClose={handleCloseExportModal}>
				<DialogTitle id="delete-dialog-title">Export Data</DialogTitle>
				<DialogContent>
					<DialogContentText sx={{mb:2}}>
						Select the date range you want to export between
					</DialogContentText>
					<FormControl fullWidth>
						<Grid container sx={{ mb: 2, maxWidth: '100%' }} >
							<Card sx={{width:'50%'}}>
								<div style={{display:'inline-flex', justifyContent:'center'}}>
									<DialogContentText sx={{maxWidth:'40%', paddingTop:'15%'}}>
										Start Date:
									</DialogContentText>
										<TextField 
										id="date-start" 
										className="form-control" 
										name="startDate"
										value={dashboard.filters.startDate?.toISODate() ?? ''} 
										type="date" 
										onChange={(event) => handleStartDateChange(event)}
										style={{maxWidth:'60%', paddingLeft:'20px', paddingTop:'10%', paddingBottom:'10%'}} />
								</div>
							</Card>
							<Card sx={{width:'50%'}}>
								<div style={{display:'inline-flex', justifyContent:'center'}}>
									<DialogContentText sx={{maxWidth:'40%', paddingTop:'15%'}}>
										End Date:
									</DialogContentText>									
										<TextField 
										id="date-stop" 
										className="form-control" 
										name="startDate"
										value={dashboard.filters.stopDate?.toISODate() ?? ''} 
										type="date" 
										onChange={(event) => handleStopDateChange(event)}
										style={{maxWidth:'60%', paddingLeft:'20px', paddingTop:'10%'}} />
								</div>
							</Card>
						</Grid>
					</FormControl>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseExportModal}>Cancel</Button>
					<Button onClick={handleOpenPreviewModal} autoFocus variant="contained">Preview</Button>
					<Button onClick={handleExportGraphData} autoFocus variant="contained">Submit</Button>
				</DialogActions>
			</Dialog>

			{
			(dashboard.preview.lineItems !== undefined && dashboard.preview.lineItems.length > 0) &&
			<Dialog maxWidth="xl" fullWidth open={dashboard.preivewModal} onClose={handleClosePreviewModal}>
				<DialogTitle>Preview Export Data</DialogTitle>
				<Table>
					<TableHead>
						<TableRow>
						{dashboard.preview.headers.map((item) => (
							<TableCell>{item}</TableCell>
						))}
						</TableRow>
					</TableHead>
					<TableBody>
					{dashboard.preview.lineItems.map((item) => (
						<TableRow>
							<TableCell>{item.dateCreated}</TableCell>
							<TableCell>{item.createdBy}</TableCell>
							<TableCell>{item.kolName}</TableCell>
							<TableCell>{item.quarter}</TableCell>
							<TableCell>{item.region}</TableCell>
							<TableCell>{item.patientPopulation}</TableCell>
							<TableCell>{item.levelOfInfluence}</TableCell>
							<TableCell>{item.yearOfInsight}</TableCell>
							<TableCell>{item.sourceOfInsight}</TableCell>
							{item.beliefs !== null && item.beliefs.length > 0 ? item.beliefs.map((belief) => (
								<TableCell>{belief}</TableCell>
							)) : <></>}
							{item.answers !== null && item.answers.length > 0 ? item.answers.map((question) => (
								<TableCell>{question.join(', ')}</TableCell>
							)) : <></>}
							<TableCell>{item.generalNote}</TableCell>
							<TableCell>{item.followUpNote}</TableCell>
						</TableRow>

					))}	
					</TableBody>
				</Table>
			</Dialog>
			}
		</>
	)
}

export default Dashboard;
