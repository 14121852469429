import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import {
	Box,
  Card,
  CardHeader,
  CardContent
} from '@mui/material';
import { QuestionProps } from '../utils/types';
import Constants from '../utils/constants'

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
	responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      position: "bottom" as const
    }
  },
}

export interface PieChartProps {
  question: QuestionProps
}

const PieChart : React.FunctionComponent<PieChartProps> = (props) => {

  const {question} = props;

  const labels = [] as string[]
  const data = [] as number[]

  question.options.filter(x => x.value !== Constants.NotAvailableOptionValue).forEach(x => {
    labels.push(x.value)
    data.push(x.data ?? 0)
  })

  const chartData = {
    labels,
    datasets: [
      {
        label: '# of Votes',
        data: data,
        backgroundColor: [
          'rgba(33, 150, 243, 0.75)', // blue
          'rgba(255, 36, 42, 0.75)',  // red
          'rgba(156, 39, 176, 0.75)', // purple
          'rgba(76, 175, 80, 0.75)',  // green
          'rgba(255, 152, 0, 0.75)',  // yellow
          'rgba(233, 30, 99, 0.75)',  // pink
        ],
        borderColor: [
          'rgba(33, 150, 243, 1)',
          'rgba(255, 36, 42, 1)',
          'rgba(156, 39, 176, 1)',
          'rgba(76, 175, 80, 1)',
          'rgba(255, 152, 0, 1)',
          'rgba(233, 30, 99, 1)'
        ],
        borderBackgroundColor: [
          'rgba(33, 150, 243, 1)',
          'rgba(255, 36, 42, 1)',
          'rgba(156, 39, 176, 1)',
          'rgba(76, 175, 80, 1)',
          'rgba(255, 152, 0, 1)',
          'rgba(233, 30, 99, 1)'
        ],
        borderWidth: 1,
      },
    ],
  };

	return (
		<Box >
      <Card>
        <CardHeader title={question.question}></CardHeader>
        <CardContent>
          <Box sx={{maxHeight:'200px', height: '100%'}}>
            <Pie data={chartData} options={options} height={200} />
          </Box>
        </CardContent>
      </Card>
		</Box>
	)
}

export default PieChart;